'use client';

import { type ReactNode, createContext, useContext } from 'react';
import type { TokensType } from '../../styles/tokens';

const TenantContext = createContext<{
    appname?: string;
    logo?: string;
    favicon?: string;
    landpage?: string;
    theme?: string;
    themeTokens?: TokensType;
  }>({});

const TenantProvider = ({
  tenantConfig,
  children,
}: {
  tenantConfig: {
    appname: string;
    logo: string;
    favicon: string;
    landpage: string;
    theme: string;
    themeTokens: TokensType;
  };
  children: ReactNode;
}) => {
  return (
    <TenantContext.Provider value={tenantConfig}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;

export const useTenantConfig = () => {
  return useContext(TenantContext);
};
